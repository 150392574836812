import React, { useEffect, useState } from "react";
import  styles from "./menu.module.scss";
import DatePicker from "react-datepicker"
import stylesGeneric from "../../styles/generics.module.scss";
import { getCookie } from "../../methods/cookie";
import {useNavigate, useParams} from "react-router-dom";
import {HomeButton} from "../../components/HomeButton/HomeButton";
import {AppConfig, AppConfigs} from "../../interfaces/AppConfig";
import {fetchWrapper, postDesign} from "../../methods/connector";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGear, faPlus} from "@fortawesome/free-solid-svg-icons";
import {Dropdown} from "../../components/dropdown/Dropdown";
import {SaveButton} from "../../components/SaveButton/SaveButton";
import {Background} from "../../components/background/Background";
import {getConstants} from "../../methods/getEvents";
import {logout, openTab} from "../../methods/helpers";
import "react-datepicker/dist/react-datepicker.css";
import {LoadingOverlay} from "../../components/loadingOverlay/loading";

const _ = require('lodash');

let img_lan = require(`../../resources/images/language_icon.png`)
let img_perm = require(`../../resources/images/permission-icon.png`)

// list all stages for the given config, with a button to open and edit them
export const ConfigMenu = () => {
    const routeParams = useParams();
    const designTag = routeParams.designTag;

    const navigate = useNavigate();
    const jwt = getCookie('jwt');
    const [design, setDesign] = useState<AppConfigs>();
    const [pageNames, setPageNames] = useState<string[]>([]);
    const [searchStringDesign, setSearchStringDesign] = useState<string>('');
    const [add, setAdd] = useState<boolean>(false);
    const [addString, setAddString] = useState<string>('');
    const [deleteTag, setDeleteTag] = useState<string>('');
    const [settingsVisible, setSettingsVisible] = useState<boolean>(false);
    const [constantsVisible, setConstantsVisible] = useState<boolean>(false);
    const [dateVisible, setDateVisible] = useState<boolean>(false);
    const [date, setDate] = useState<Date>(new Date());
    const [designs, setDesigns] = useState<string[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    const openDesign = (pageName: string) => {
        navigate(pageName);
    }

    const copyPage = (pageName: string) => {
        if (!designTag) {
            return;
        }
        const el = design?.configs?.[pageName];
        if (!el) {
            return;
        }
        navigator.clipboard.writeText(JSON.stringify({...el, pageName}))
    }

    const pastePage = async () => {
        const clipboard = await navigator.clipboard.readText();
        if (!clipboard || !design) {
            return;
        }
        try {
            const el: AppConfig & {pageName?: string} = JSON.parse(clipboard);
            if (!el || !el.design || !el.settings || !el.pageName) {
                return;
            }
            const pageName = el.pageName;
            delete el.pageName;
            const designClone: any = _.cloneDeep(design);
            const elements = designClone?.configs;
            elements[pageName] = el;
            setLoading(true);
            const data = await postDesign(designClone, jwt)
            setLoading(false);
            if (data.status !== 200) {
                return;
            }
            getDesign();
        } catch (e) {
            return;
        }
    }

    const addEntry = async () => {
        if (!addString || design?.configs?.[addString]) {
            return;
        }
        const d = _.cloneDeep(design)
        d.configs[addString] = {design: {}, settings: {}};
        setLoading(true);
        const data = await postDesign(d, jwt)
        setLoading(false);
        if (data.status !== 200) {
            return;
        }
        getDesign();
        setAddString("");
        setAdd(false);
    }

    const deleteEntry = async (pageName: string) => {
        setDeleteTag('');
        const d = _.cloneDeep(design)
        delete d?.configs?.[pageName];
        setLoading(true);
        const data = await postDesign(d, jwt)
        setLoading(false);
        if (data.status !== 200) {
            return;
        }
        getDesign();
    }

    const addNewParam = (key: string) => {
        let designClone: any = _.cloneDeep(design);
        if (!(designClone?.settings?.appParameters)) {
            designClone.settings.appParameters = {};
        }
        if (designClone?.settings?.appParameters?.[key] !== undefined) {
            return;
        }
        designClone.settings.appParameters[key] = "";
        setDesign(designClone);
    }

    const removeParam = (key: string) => {
        let designClone: any = _.cloneDeep(design);
        if (!(designClone?.settings?.appParameters)) {
            return;
        }
        if (designClone?.settings?.appParameters?.[key] === undefined) {
            return;
        }
        delete designClone.settings.appParameters[key];
        setDesign(designClone);
    }

    const setVal = (baseKey: string, val: any) => {
        let designClone: any = _.cloneDeep(design);
        let objInner: any = designClone;
        if (!objInner) {
            return;
        }
        const keySplit = baseKey.split('.');
        for (const key of keySplit.slice(0, keySplit.length - 1)) {
            if (!objInner?.[key]) {
                objInner[key] = {};
            }
            objInner = objInner[key];
        }
        objInner[keySplit[keySplit.length - 1]] = val;
        setDesign(designClone);
    }

    const getDesigns = async() => {
        let url = `${process.env.REACT_APP_URL}/designList?name_only=true`;
        setLoading(true);
        const data = await fetchWrapper(url, jwt, 'get');
        setLoading(false);
        if (data.status === 401) {
            logout();
            return;
        }
        if (data.status !== 200) {
            return;
        }
        const r: AppConfigs[] = await data.json();
        setDesigns(r.map(el => el.designTag).sort());
    }

    const getDesign = async() => {
        let url = `${process.env.REACT_APP_URL}/designTag/${designTag}`;
        setLoading(true);
        const data = await fetchWrapper(url, jwt, 'get');
        setLoading(false);
        if (data.status === 401) {
            logout();
            return;
        }
        if (data.status !== 200) {
            return;
        }
        const r: AppConfigs = await data.json();
        setDesign(r);
        const pageNames = Object.keys(r.configs);
        setPageNames(pageNames)
    }

    const postConfig = async () => {
        if (!design) {
            return;
        }
        setLoading(true);
        await postDesign(design, jwt)
        setLoading(false);
        setSettingsVisible(false);
    }

    useEffect(() => {
        getDesign();
        getDesigns();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className={stylesGeneric["page"]}>
            <Background/>
            {/*<HomeButtonRow/>*/}
            <div className={stylesGeneric["settings-button-row"]}>
                <div className={stylesGeneric["settings-button"]}>
                    <HomeButton/>
                </div>
                <div className={stylesGeneric["settings-button"]}>
                    <button className={stylesGeneric['generic-button-wrapper']} onClick={() => {
                        setAdd(!add);
                        setSettingsVisible(false);
                    }}>
                        <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faPlus}/>
                    </button>
                </div>
                <div className={stylesGeneric["settings-button"]}>
                    <button className={stylesGeneric['generic-button-wrapper']} onClick={() => {
                        setSettingsVisible(!settingsVisible);
                        setAdd(false);
                    }}>
                        <FontAwesomeIcon className={stylesGeneric['generic-button']} icon={faGear}/>
                    </button>
                </div>
                <div className={stylesGeneric["settings-button"]}>
                    <button
                      className={stylesGeneric['generic-button-wrapper']}
                      onMouseDown={(e) => openTab(e, `/generateConfigLanguage/${designTag}`)}
                    >
                        <img
                          className={stylesGeneric['generic-button']}
                          src={img_lan}
                        />
                    </button>
                </div>
                <div className={stylesGeneric["settings-button"]}>
                    <button
                      className={stylesGeneric['generic-button-wrapper']}
                      onMouseDown={(e) => openTab(e, `/generateConfigPermission/${designTag}`)}
                    >
                        <img
                          className={stylesGeneric['generic-button']}
                          src={img_perm}
                        />
                    </button>
                </div>
            </div>
            <div className={stylesGeneric["content-wrapper"]}>
                <div className={stylesGeneric["page-wrapper"]}>
                    {/*<div className={styles["type-wrapper"]}>*/}
                    {/*    <FontAwesomeIcon icon={faGear} className={styles['home-button']} onClick={() => {setSettingsVisibile(!settingsVisible); setAdd(false);}}/>*/}
                    {/*</div>*/}
                    <table>
                        <thead>
                        <tr>
                            <td>
                                <input
                                  className={styles["search-bar"]}
                                  value={searchStringDesign}
                                  placeholder="Design..."
                                  onChange={(el) => setSearchStringDesign(el.currentTarget.value)}
                                >
                                </input>
                            </td>
                            <td/>
                            <td/>
                            <td/>
                        </tr>
                        </thead>
                        <tbody>
                            {Object.keys(design?.configs ?? {}).filter(e => e.includes(searchStringDesign)).sort().map(el => {
                                return(<tr className={stylesGeneric["table-row"]} key={el}>
                                    <td className={stylesGeneric["table-cell-left"]}>
                                        {el}
                                    </td>
                                    <td className={stylesGeneric["table-cell"]}>
                                        <input
                                          type="button"
                                          className={stylesGeneric["dropdown-wrapper"]}
                                          onMouseDown={(e) => {
                                              e.stopPropagation();
                                              openTab(e, `${designTag}/${el}`)
                                          }}
                                          value="Edit"
                                        />
                                    </td>
                                    <td className={stylesGeneric["table-cell"]}>
                                        <input
                                          type="button"
                                          className={stylesGeneric["dropdown-wrapper"]}
                                          onMouseDown={(e) => {
                                              e.stopPropagation();
                                              copyPage(el);
                                          }}
                                          value="Copy"
                                        />
                                    </td>
                                    <td className={stylesGeneric["table-cell"]}>
                                        <input
                                          type="button"
                                          className={stylesGeneric["dropdown-wrapper"]}
                                          onClick={(e) => {
                                              e.stopPropagation();
                                              setDeleteTag(el)
                                          }}
                                          value="Delete"
                                        />
                                    </td>
                                </tr>)
                            })}
                            <tr>
                                <td className={stylesGeneric["table-cell"]}>
                                    {
                                        navigator.userAgent.includes('Firefox')
                                            ? null
                                            : <input
                                                type="button"
                                                className={stylesGeneric["dropdown-wrapper"]}
                                                onMouseDown={(e) => {e.stopPropagation();
                                                pastePage();
                                        }}
                                    value="Paste"
                                        />
                                    }
                                </td>
                                <td/>
                                <td/>
                                <td/>
                            </tr>
                        </tbody>
                    </table>
                </div>
                {
                    add
                      ?
                      <div className={styles["add-wrapper"]}>
                          <input value={addString} placeholder={'Enter new page name...'} onChange={(e) => {
                              setAddString(e.target.value)
                          }} className={styles["add-input"]}/>
                          <div className={styles["add-buttons"]}>
                              <input className={styles["add-button"]} value={"OK"} onClick={addEntry} type="button"/>
                              <input className={styles["add-button"]} value={"Close"} onClick={() => {setAdd(false); setAddString('')}} type="button"/>
                            </div>
                        </div>
                    : null
                }
                {
                    settingsVisible
                      ?
                      <div className={styles["config-wrapper"]}>
                          <table className={styles["config-table"]}>
                              <tbody>
                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          Is this config finished?
                                      </div>
                                  </td>
                                  <td>
                                      <input
                                        readOnly={true}
                                        type={'checkbox'}
                                        checked={design?.settings?.export}
                                        onClick={() => {
                                            setVal('settings.export', !design?.settings?.export)
                                        }}
                                      />
                                  </td>
                              </tr>
                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          target API url
                                      </div>
                                  </td>
                                  <td>
                                      <Dropdown
                                        currentVal={design?.settings.targetAPI ?? ""}
                                        valueList={(process.env.REACT_APP_MEDIA_URLS ?? '').split(',').filter((e) => e !== '')}
                                        onChange={(val: string) => {
                                            setVal('settings.targetAPI', val)
                                        }}
                                      />

                                  </td>
                              </tr>
                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          calculation URLs
                                      </div>
                                  </td>
                                  <td>
                                      <input
                                        className={styles['value-input-string']}
                                        value={design?.settings.calcURLs ?? ''}
                                        onChange={(e) => setVal('settings.calcURLs', e.target.value)}
                                      />

                                  </td>
                              </tr>
                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          initialStage
                                      </div>
                                  </td>
                                  <td>
                                      <Dropdown
                                        currentVal={design?.settings.initialStage ?? ""}
                                        valueList={pageNames}
                                        designTag={designTag}
                                        onChange={(val: string) => {
                                            setVal('settings.initialStage', val)
                                        }}
                                      />

                                  </td>
                              </tr>
                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          homeStage
                                      </div>
                                  </td>
                                  <td>
                                      <Dropdown
                                        currentVal={design?.settings.homeStage ?? ""}
                                        valueList={pageNames}
                                        designTag={designTag}
                                        onChange={(val: string) => {
                                            setVal('settings.homeStage', val)
                                        }}
                                      />

                                  </td>
                              </tr>

                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          languageStage
                                      </div>
                                  </td>
                                  <td>
                                      <Dropdown
                                        currentVal={design?.settings.languageStage ?? ""}
                                        valueList={pageNames}
                                        designTag={designTag}
                                        onChange={(val: string) => {
                                            setVal('settings.languageStage', val)
                                        }}
                                      />

                                  </td>
                              </tr>

                              {/*<tr className={styles["config-row"]}>*/}
                              {/*    <td/>*/}
                              {/*    <td>*/}
                              {/*        <div className={styles["config-key"]}>*/}
                              {/*            usbStage*/}
                              {/*        </div>*/}
                              {/*    </td>*/}
                              {/*    <td>*/}
                              {/*        <Dropdown*/}
                              {/*          currentVal={design?.settings.usbStage ?? ""}*/}
                              {/*          valueList={pageNames}*/}
                              {/*          designTag={designTag}*/}
                              {/*          onChange={(val: string) => {*/}
                              {/*              setVal('settings.usbStage', val)*/}
                              {/*          }}*/}
                              {/*        />*/}

                              {/*    </td>*/}
                              {/*</tr>*/}

                              {/*<tr className={styles["config-row"]}>*/}
                              {/*    <td/>*/}
                              {/*    <td>*/}
                              {/*        <div className={styles["config-key"]}>*/}
                              {/*            permissionStage*/}
                              {/*        </div>*/}
                              {/*    </td>*/}
                              {/*    <td>*/}
                              {/*        <Dropdown*/}
                              {/*          currentVal={design?.settings.permissionStage ?? ""}*/}
                              {/*          valueList={pageNames}*/}
                              {/*          designTag={designTag}*/}
                              {/*          onChange={(val: string) => {*/}
                              {/*              setVal('settings.permissionStage', val)*/}
                              {/*          }}*/}
                              {/*        />*/}

                              {/*    </td>*/}
                              {/*</tr>*/}

                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          updateStage
                                      </div>
                                  </td>
                                  <td>
                                      <Dropdown
                                        currentVal={design?.settings.updateStage ?? ""}
                                        valueList={pageNames}
                                        designTag={designTag}
                                        onChange={(val: string) => {
                                            setVal('settings.updateStage', val)
                                        }}
                                      />

                                  </td>
                              </tr>

                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          setupPage
                                      </div>
                                  </td>
                                  <td>
                                      <Dropdown
                                        currentVal={design?.settings.setupStage ?? ""}
                                        valueList={pageNames}
                                        designTag={designTag}
                                        onChange={(val: string) => {
                                            setVal('settings.setupStage', val)
                                        }}
                                      />

                                  </td>
                              </tr>

                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          reconnectPage
                                      </div>
                                  </td>
                                  <td>
                                      <Dropdown
                                        currentVal={design?.settings.reconnectStage ?? ""}
                                        valueList={pageNames}
                                        designTag={designTag}
                                        onChange={(val: string) => {
                                            setVal('settings.reconnectStage', val)
                                        }}
                                      />

                                  </td>
                              </tr>

                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          offlinePage
                                      </div>
                                  </td>
                                  <td>
                                      <Dropdown
                                        currentVal={design?.settings.offlineStage ?? ""}
                                        valueList={pageNames}
                                        designTag={designTag}
                                        onChange={(val: string) => {
                                            setVal('settings.offlineStage', val)
                                        }}
                                      />

                                  </td>
                              </tr>

                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          noConfigPage
                                      </div>
                                  </td>
                                  <td>
                                      <Dropdown
                                        currentVal={design?.settings.noConfigStage ?? ""}
                                        valueList={pageNames}
                                        designTag={designTag}
                                        onChange={(val: string) => {
                                            setVal('settings.noConfigStage', val)
                                        }}
                                      />

                                  </td>
                              </tr>

                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          numMeasurementsAox
                                      </div>
                                  </td>
                                  <td>
                                      <input
                                        className={styles['value-input-number']}
                                        type='number'
                                        value={design?.settings.numMeasurementsAox ?? 0}
                                        onChange={(e) => setVal('settings.numMeasurementsAox', Number(e.target.value))}
                                      />
                                  </td>
                              </tr>
                              <tr className={styles["config-row"]}>
                                  <td/>
                                  <td/>
                                  <td>
                                      <input
                                        className={styles['value-input-button']}
                                        type='button'
                                        value={'App constants'}
                                        onClick={() => {
                                            setSettingsVisible(false);
                                            setConstantsVisible(true);
                                        }}
                                        // onChange={(e) => setVal('settings.numMeasurementsAox', Number(e.target.value))}
                                      />
                                  </td>
                              </tr>
                              <tr className={styles["config-row"]}>
                                  <td>
                                      <input
                                        readOnly={true}
                                        type={'checkbox'}
                                        checked={design?.settings?.release?.shouldRestrict}
                                        onClick={() => {
                                            setVal('settings.release.shouldRestrict', !design?.settings?.release?.shouldRestrict)
                                        }}
                                      />
                                  </td>
                                  <td>
                                      <div className={styles["config-key"]}>
                                          Release date (UTC)
                                      </div>
                                  </td>
                                  <td>
                                      {/*<input*/}
                                      {/*  className={styles['value-input-button']}*/}
                                      {/*  type='button'*/}
                                      {/*  value={'Release date'}*/}
                                      {/*  onClick={() => {setDateVisible(true);}}*/}
                                      {/*  // onChange={(e) => setVal('settings.numMeasurementsAox', Number(e.target.value))}*/}
                                      {/*/>*/}
                                      {
                                          design?.settings?.release?.shouldRestrict
                                            ? <DatePicker
                                              className={styles['date-picker']}
                                              selected={typeof design?.settings?.release?.date === 'string'
                                                ? new Date(design?.settings?.release?.date)
                                                : design?.settings?.release?.date ?? new Date()
                                              }
                                              onChange={(d) => setVal('settings.release.date', d)}
                                              showTimeSelect
                                              dateFormat="MMMM d, yyyy h:mm aa"
                                            />
                                            : null
                                      }
                                  </td>
                              </tr>
                              {
                                  design?.settings?.release?.shouldRestrict
                                    ?
                                    <tr className={styles["config-row"]}>
                                        <td/>
                                        <td>
                                            <div className={styles["config-key"]}>
                                                Before that date, use
                                            </div>
                                        </td>
                                        <td>
                                            <Dropdown
                                              currentVal={design?.settings.release?.alternateDesign ?? ""}
                                              valueList={designs.filter((el) => el !== designTag)}
                                              onChange={(val: string) => {
                                                  setVal('settings.release.alternateDesign', val)
                                              }}
                                            />

                                        </td>
                                    </tr>

                                    : null

                              }


                              </tbody>

                          </table>
                          <div className={styles["config-buttons"]}>
                              <div className={styles["config-button"]}>
                                  <SaveButton onClick={postConfig}/>
                              </div>
                              <input className={styles["config-button"]} type={'button'} value={'Cancel'}
                                     onClick={() => {
                                         setAdd(false);
                                         setSettingsVisible(false);
                                     }}/>
                          </div>
                      </div>
                      : null
                }
                {
                    constantsVisible
                      ?
                      <div className={styles["param-wrapper"]}>
                          <table className={styles["param-table"]}>
                              <thead>
                              <tr className={styles['param-row']}>
                              <td className={styles['param-cell-key']}>
                                        Param
                                    </td>
                                    <td className={styles['param-cell-value']}>
                                        Value
                                    </td>
                                    <td/>
                                </tr>
                              </thead>
                              <tbody>
                              {
                                  Object.entries(design?.settings?.appParameters ?? {}).map(([key, val]) => {
                                      return <tr className={styles['param-row']} key={key}>
                                          <td className={styles['param-cell-key']}>
                                              <p
                                                className={styles['param-key']}
                                              >
                                                  {key}
                                              </p>
                                          </td>
                                          <td className={styles['param-cell-value']}>
                                              <input
                                                className={styles['param-edit-key']}
                                                value={val}
                                                onChange={(e) => setVal(`settings.appParameters.${key}`, e.target.value) }
                                              />

                                          </td>
                                          <td className={styles['param-cell-delete']}>
                                              <input
                                                className={styles['param-delete-key']}
                                                type={'button'}
                                                value={'Delete'}
                                                onClick={() => removeParam(key)}
                                              />

                                          </td>
                                      </tr>
                                  })
                              }
                              <tr className={styles["param-row"]}>
                                  <td className={styles['param-cell-key']}>
                                      <Dropdown
                                        currentVal={'New param...'}
                                        valueList={getConstants()}
                                        onChange={(val: string) => {
                                            addNewParam(val);
                                        }}
                                      />
                                  </td>
                                  <td/>
                                  <td/>
                              </tr>
                              </tbody>
                          </table>
                          <div className={styles["param-buttons"]}>
                              <div className={styles["param-button"]}>
                                  <input
                                    className={styles['param-button-input']}
                                    type={'button'}
                                    value={'OK'}
                                    onClick={() => {setConstantsVisible(false); setSettingsVisible(true);}}
                                  />
                              </div>
                              <div className={styles["param-button"]}>
                                  <input
                                    className={styles['param-button-input']}
                                    type={'button'}
                                    value={'Cancel'}
                                    onClick={() => {setConstantsVisible(false);}}
                                  />
                              </div>
                          </div>
                      </div>

                      :
                        null
                }
                {
                    deleteTag !== ''
                    ?
                      <div className={styles["add-wrapper"]}>
                          <p>
                              Delete {deleteTag}?
                          </p>
                          <div className={styles["add-buttons"]}>
                              <input className={styles["add-button"]} value={"OK"} onClick={(e) => {e.stopPropagation(); deleteEntry(deleteTag)}} type="button"/>
                              <input className={styles["add-button"]} value={"Cancel"} onClick={() => {setDeleteTag(''); }} type="button"/>
                          </div>
                      </div>
                    : null
                }
                {
                    dateVisible
                  ? <DatePicker
                      className={styles['date-picker']}
                      selected={date}
                      onChange={(d) => {setDate(d ?? new Date())}}/>
                  : null
                }
            </div>
            <LoadingOverlay loading={loading}/>
        </div>
    )
}
