export const getLanguageCodes = (): Record<string, string> => {
  return {
    'BG': "Български",
    'CS': "Česky",
    'DA': "Dansk",
    'DE': "Deutsch",
    'EL': "Ελληνική",
    'EN-GB': "English (UK)",
    'EN-US': "English (US)",
    'ES': "Español",
    'ET': "Eesti",
    'FI': "Suomalainen",
    'FR': "Français",
    'HU': "Magyar",
    'ID': "Bahasa Indonesia",
    'IT': "Italiano",
    'JA': "日本語",
    'KO': "한국어",
    'LT': "Lietuvių kalba",
    'LV': "Latviešu",
    'NB': "Norsk (bokmål)",
    'NL': "Nederlands",
    'PL': "Polski",
    'PT-BR': "Português (brasileiro)",
    'PT-PT': "Português",
    'RO': "Românesc",
    'RU': "Русский",
    'SK': "Slovenská",
    'SL': "Slovaška",
    'SV': "Svenska",
    'TR': "Türkçe",
    'UK': "Українська",
    'ZH': "中文（简体）",
    'ZH-TW': "中文（繁體）",
    'MN': "Монгол",
    'VI': "Tiếng Việt",
    'TH': "ไทย",

  }
}

export const canTranslate = (languageCode: string): boolean => {
  switch (languageCode) {
    case 'BG': return true;
    case 'CS': return true;
    case 'DA': return true;
    case 'DE': return true;
    case 'EL': return true;
    case 'EN-GB': return true;
    case 'EN-US': return true;
    case 'ES': return true;
    case 'ET': return true;
    case 'FI': return true;
    case 'FR': return true;
    case 'HU': return true;
    case 'ID': return true;
    case 'IT': return true;
    case 'JA': return true;
    case 'KO': return true;
    case 'LT': return true;
    case 'LV': return true;
    case 'NB': return true;
    case 'NL': return true;
    case 'PL': return true;
    case 'PT-BR': return true;
    case 'PT-PT': return true;
    case 'RO': return true;
    case 'RU': return true;
    case 'SK': return true;
    case 'SL': return true;
    case 'SV': return true;
    case 'TR': return true;
    case 'UK': return true;
    case 'ZH': return true;
    case 'ZH-TW': return false;
    case 'VI': return false;
    case 'TH': return false;
    case 'MN': return false;

    default: return false;

  }
}

export const getOrientations = (): string[] => {
  return [
    'landscape',
    'landscape-primary',
    'landscape-secondary',
    'portrait',
    'portrait-primary',
    'portrait-secondary'
  ]
}

export const getScalingModes = (): string[] => {
  return [
    'contain',
    'cover',
    'fit-width',
    'fit-height',
    'stretch'
  ]
}

export const getLanguages = (): string[] => {
  return Object.keys(getLanguageCodes());
}

export const getButtonFunctionList = (): string[] => {
  return [
    'setState',
    'factoryReset',
    'refresh'
  ]
}

export const getVarGroup = (): string[] => {
  return [
    'temp', //random temporary store
    'persistentValues', //Language, Serial, ...
    'appSettings', //handRecognition, batteryCutoff, ...
    'additionalData', //upload/fetchResult
    'postParameters', //postRequest
    'valuesToSend' //postIframe
  ]
}

export const getConstants = (): string[] => {
  return [
    'handRecognitionLimitDarkUpper',
    'handRecognitionLimitBrightLower',
    'handRecognitionLimitBrightUpper',
    'batteryCutoffVoltage',
    'batteryPrewarningVoltage',
  ]
}

export const getParamTypes = (): string[] => {
  return [
    'number',
    'string',
    'boolean',
    'function'
  ]
}

export const getFunctionTypes = (): [string[], string[], string[]] => {
  return [
    [
      'add 1',
      'subtract 1',
      '+',
      '-',
      '*',
      '/',
      'random'
    ],
    [
      '+',
      '-',
      '*',
      '/'
    ],
    [
      'random'
    ]
  ]
}

export const getKnownVars = (group: string): string[] => {
  switch (group) {
    case 'temp': {
      return [
        'SERIAL',
        'RESULT.aox',
        'RESULT.aox_q',
      ]
    }
    case 'persistentValues': {
      return [
        'SCANNER_WIFI',
        'SCANNER_SERIAL',
        'SELECTED_LANGUAGE',
        'SCANNER_KEY',
      ]
    }
    case 'appSettings': {
      return [
        'handRecognitionLimitDarkUpper',
        'handRecognitionLimitBrightLower',
        'handRecognitionLimitBrightUpper',
        'batteryCutoffVoltage',
        'batteryPrewarningVoltage',
      ]
    }
    default: {
      return []
    }
  }
}

export const getSiteFunctionList = (): string[] => {
  return [
    'if',
    'resetAll',
    'checkInternet',
    'scanQRScanner',
    'scanQRGeneric',
    'fetchScannerWifi',
    'validateScanner',
    'checkFirmware',
    'updateFirmware',
    // 'selectAOXPrecise',
    'connectScanner',
    'getScannerFirmware',
    'handOnRecognition',
    'measureAOX',
    // 'measureAOXHalf',
    'measureHeart',
    'initMeasureHeart',
    'getMeasurementHeart',
    'measureHeartTest',
    'handOffRecognition',
    'repeatMeasurement',
    'connectToServer',
    'uploadDataAOX',
    'uploadDataHeart',
    'uploadDataHeartTest',
    'repeatUpload',
    'deleteResult',
    'fetchResult',
    'genResultURL',
    'getConfig',
    'displayResults',
    'goToHome',
    'copyValues',
    'setValues',
    'refresh',
    'updateApp',
    'getRequest',
    'postRequest',
    'downloadMedia',
    'stashMeasurement',
    'popMeasurement',
    'cleanupMeasurement'
  ];
}

export const getEventList = (functionName: string): string[] => {
  switch (functionName) {
    case 'if': {
      return ['onTrue', 'onFalse', 'onBadInput']
    }
    case 'checkInternet': {
      return ['onSuccess', 'onNoInternet']
    }
    case 'scanQRScanner': {
      return ['onSuccess', 'onCancelled']
    }
    case 'scanQRGeneric': {
      return ['onSuccess', 'onCancelled']
    }
    case 'fetchScannerWifi': {
      return ['onSuccess', 'onAmbiguous']
    }
    case 'validateScanner': {
      return ['onSuccess', 'onError', 'onErrorProduct']
    }
    case 'checkFirmware': {
      return ['onSuccess', 'onUpdateNeeded', 'onScannerSanityFail']
    }
    case 'updateFirmware': {
      return ['onSuccess']
    }
    case 'selectAOXPrecise': {
      return ['onReduceQueries', 'onNormalProcess']
    }
    case 'connectScanner': {
      return ['onSuccess', 'onErrorScanner', 'onErrorWrongScanner','onErrorWifi','onErrorUSB']
    }
    case 'getScannerFirmware': {
      return ['onSuccess', 'onErrorProduct', 'onErrorOutdated', 'onErrorBattery']
    }
    case 'handOnRecognition': {
      return ['onSuccess']
    }
    case 'measureAOX': {
      return ['onSuccess', 'onBadMeasurement']
    // }
    // case 'measureAOXHalf': {
    //   return ['onSuccess', 'onBadMeasurement']
    }
    case 'measureHeart': {
      return ['onSuccess', 'onBadMeasurement']
    }
    case 'initMeasureHeart': {
      return ['onSuccess', 'onBadMeasurement']
    }
    case 'getMeasurementHeart': {
      return ['onSuccess', 'onBadMeasurement']
    }
    case 'measureHeartTest': {
      return ['onSuccess', 'onBadMeasurement']
    }
    case 'handOffRecognition': {
      return ['onSuccess']
    }
    case 'repeatMeasurement': {
      return ['onSuccess', 'onRepeat']
    }
    case 'connectToServer': {
      return ['onSuccess', 'onErrorInternet']
    }
    case 'uploadDataAOX': {
      return ['onSuccess', 'onInsufficientMeasurements', 'onErrorBattery', 'onError100', 'onError90', 'onError80', 'onError70', 'onError60', 'onError50', 'onError45', 'onError44', 'onError']
    }
    case 'uploadDataHeart': {
      return ['onSuccess', 'onErrorBattery', 'onError70', 'onError60',  'onError']
    }
    case 'uploadDataHeartTest': {
      return ['onSuccess', 'onBadMeasurement']
    }
    case 'repeatUpload': {
      return ['onSuccess', 'onInsufficientMeasurements', 'onRepeat']
    }
    case 'deleteResult': {
      return ['onSuccess']
    }
    case 'fetchResult': {
      return ['onSuccess', 'onErrorNoMeasurement']
    }
    case 'displayResults': {
      return ['onSuccess', 'onMissingResult']
    }
    case 'genResultURL': {
      return ['onSuccess', 'onMissingResult']
    }
    case 'getConfig': {
      return ['onSuccess', 'onError', 'onNoDesignTag']
    }
    case 'goToHome': {
      return ['onSuccess']
    }
    case 'copyValues': {
      return ['onSuccess']
    }
    case 'setValues': {
      return ['onSuccess']
    }
    case 'refresh': {
      return ['onSuccess']
    }
    case 'updateApp': {
      return ['onSuccess']
    }
    case 'getRequest': {
      return ['onSuccess', 'onFail', 'onMissingURL']
    }
    case 'postRequest': {
      return ['onSuccess', 'onFail', 'onMissingURL']
    }
    case 'downloadMedia': {
      return ['onSuccess', 'onFail', 'onMissingURL']
    }
    case 'stashMeasurement': {
      return ['onSuccess', 'onInsufficientMeasurements']
    }
    case 'popMeasurement': {
      return ['onSuccess', 'onInsufficientMeasurements', 'onLast']
    }
    case 'cleanupMeasurement': {
      return ['onSuccess']
    }
    default: {
      return []
    }
  }
}

export const getAdditionalKeys = (elementType: string): {key: string, mandatory: boolean, ifFilled?: string, values?: string[], allowOther?: boolean, fieldType?: string}[] => {
  switch (elementType) {
    case 'button': {
      return [
        {key: 'goTo', mandatory: false},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'background.image', mandatory: false},
        {key: 'background.width', mandatory: false, ifFilled: 'background.image', fieldType: 'number'},
        {key: 'background.height', mandatory: false, ifFilled: 'background.image', fieldType: 'number'},
        {key: 'background.hasBorder', mandatory: false, ifFilled: 'background.image', values: ['true'], allowOther: false},
        {key: 'background.sectionWidth', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionHeight', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionX', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionY', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
      ]
    }
    case 'functionButton': {
      return [
        // {key: 'goTo', mandatory: false},
        {key: 'handleFunction.name', mandatory: false, values: getButtonFunctionList()},
        {key: 'handleFunction.value', mandatory: false, ifFilled: 'handleFunction.name'},
        {key: 'handleFunction.group', mandatory: false, ifFilled: 'handleFunction.name', values: getVarGroup(), allowOther: true},
        {key: 'handleFunction.argument', mandatory: false, ifFilled: 'handleFunction.name'},
        {key: 'handleFunction.isRadio', mandatory: false, ifFilled: 'handleFunction.name', values: ['true']},
        {key: 'handleFunction.buttonGroup', mandatory: false, ifFilled: 'handleFunction.name'},
        // {key: 'handleFunction.init', mandatory: false, ifFilled: 'handleFunction.name'},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'background.image', mandatory: false},
        {key: 'background.width', mandatory: false, ifFilled: 'background.image', fieldType: 'number'},
        {key: 'background.height', mandatory: false, ifFilled: 'background.image', fieldType: 'number'},
        {key: 'background.hasBorder', mandatory: false, ifFilled: 'background.image', values: ['true'], allowOther: false},
        {key: 'background.sectionWidth', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionHeight', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionX', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionY', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
      ]
    }
    case 'checkbox': {
      return [
        {key: 'handleFunction.name', mandatory: false, values: getButtonFunctionList()},
        {key: 'handleFunction.value', mandatory: false, ifFilled: 'handleFunction.name'},
        {key: 'handleFunction.group', mandatory: false, ifFilled: 'handleFunction.name', values: getVarGroup(), allowOther: true},
        {key: 'handleFunction.ignoreClick', mandatory: false, ifFilled: 'handleFunction.name', values: ['true'], allowOther: false},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
      ]
    }
    case 'input': {
      return [
        {key: 'goTo', mandatory: false},
        {key: 'handleFunction.type', mandatory: false, values: ['string', 'number']},
        {key: 'handleFunction.name', mandatory: false, values: getButtonFunctionList()},
        {key: 'handleFunction.value', mandatory: false, ifFilled: 'handleFunction.name'},
        {key: 'handleFunction.group', mandatory: false, ifFilled: 'handleFunction.name', values: getVarGroup(), allowOther: true},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
      ]
    }
    case 'barcode': {
      return [
        {key: 'goTo', mandatory: false},
        {key: 'handleFunction.name', mandatory: false, values: getButtonFunctionList()},
        {key: 'handleFunction.value', mandatory: false, ifFilled: 'handleFunction.name'},
        {key: 'handleFunction.group', mandatory: false, ifFilled: 'handleFunction.name', values: getVarGroup(), allowOther: true},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
      ]
    }
    case 'qrCode': {
      return [
        {key: 'handleFunction.value', mandatory: false},
        {key: 'handleFunction.group', mandatory: false, values: getVarGroup(), allowOther: true},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
      ]
    }
    case 'iframePost':
    case 'iframe': {
      return [
        {key: 'design', mandatory: true},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},

      ]
    }
    case 'link': {
      return [
        {key: 'url', mandatory: true},
        // {key: 'text', mandatory: false},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
      ]
    }
    case 'resource': {
      return [
        {key: 'resource', mandatory: true},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'background.image', mandatory: false},
        {key: 'background.width', mandatory: false, ifFilled: 'background.image', fieldType: 'number'},
        {key: 'background.height', mandatory: false, ifFilled: 'background.image', fieldType: 'number'},
        {key: 'background.hasBorder', mandatory: false, ifFilled: 'background.image', values: ['true'], allowOther: false},
        {key: 'background.sectionWidth', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionHeight', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionX', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionY', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
      ]
    }
    case 'slider': {
      return [
        {key: 'resource', mandatory: true},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'innerSize.width', mandatory: true},
        {key: 'innerSize.height', mandatory: true},
        {key: 'innerSize.x_key', mandatory: false},
        {key: 'innerSize.x_keyGroup', mandatory: false, ifFilled: 'innerSize.x_key'},
        {key: 'innerSize.x_min', mandatory: false, ifFilled: 'innerSize.x_key', fieldType: 'number'},
        {key: 'innerSize.x_max', mandatory: false, ifFilled: 'innerSize.x_key', fieldType: 'number'},
        {key: 'innerSize.y_key', mandatory: false},
        {key: 'innerSize.y_keyGroup', mandatory: false, ifFilled: 'innerSize.y_key'},
        {key: 'innerSize.y_min', mandatory: false, ifFilled: 'innerSize.y_key', fieldType: 'number'},
        {key: 'innerSize.y_max', mandatory: false, ifFilled: 'innerSize.y_key', fieldType: 'number'},
        {key: 'background.image', mandatory: false},
        {key: 'background.width', mandatory: false, ifFilled: 'background.image', fieldType: 'number'},
        {key: 'background.height', mandatory: false, ifFilled: 'background.image', fieldType: 'number'},
        {key: 'background.hasBorder', mandatory: false, ifFilled: 'background.image', values: ['true'], allowOther: false},
        {key: 'background.sectionWidth', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionHeight', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionX', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionY', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
      ]
    }
    case 'video': {
      return [
        {key: 'resource', mandatory: true},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'background.image', mandatory: false},
        {key: 'background.muted', mandatory: false, fieldType: 'boolean'},
        {key: 'background.width', mandatory: false, ifFilled: 'background.image', fieldType: 'number'},
        {key: 'background.height', mandatory: false, ifFilled: 'background.image', fieldType: 'number'},
        {key: 'background.hasBorder', mandatory: false, ifFilled: 'background.image', values: ['true'], allowOther: false},
        {key: 'background.sectionWidth', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionHeight', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionX', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
        {key: 'background.sectionY', mandatory: false, ifFilled: 'background.hasBorder', fieldType: 'number'},
      ]
    }
    case 'progressBar': {
      return [
        {key: 'index', mandatory: true},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
      ]
    }
    case 'progressBarHRV': {
      return [
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
      ]
    }
    case 'spinner': {
      return [
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
      ]
    }
    case 'breather': {
      return [
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
      ]
    }
    case 'h2':
    case 'p':
    case 'note': {
      return [
        // {key: 'text', mandatory: false},
        {key: 'handleFunction.value', mandatory: false},
        {key: 'handleFunction.group', mandatory: false, values: getVarGroup(), allowOther: true},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
      ]
    }
    case 'options': {
      return [
        {key: 'handleFunction.name', mandatory: true, values: getButtonFunctionList()},
        {key: 'handleFunction.value', mandatory: false, ifFilled: 'handleFunction.name'},
        {key: 'handleFunction.group', mandatory: false, ifFilled: 'handleFunction.name', values: getVarGroup(), allowOther: true},
        {key: 'handleFunction.argument', mandatory: false, ifFilled: 'handleFunction.name'},
        {key: 'handleFunction.placeholder', mandatory: false, ifFilled: 'handleFunction.name'},
        // {key: 'handleFunction.init', mandatory: false, ifFilled: 'handleFunction.name'},
        {key: 'condition.checkIf', mandatory: false},
        {key: 'condition.group', mandatory: false, ifFilled: 'condition.checkIf'},
        {key: 'condition.equalsValue', mandatory: false, ifFilled: 'condition.checkIf'},
      ]
    }
    default: {
      return [];
    }
  }
}

export const getImagesTypes = () => {
  return ['image', 'iframe', 'color'];
}

export const getMandatoryKeys = () => {
  return ['width', 'height', 'left', 'top'];
}

export const getElementTypes = () => {
  return ['barcode', 'breather', 'button', 'checkbox', 'functionButton', 'iframe', 'iframePost', 'input', 'link', 'note', 'options', 'p', 'progressBar', 'progressBarHrv', 'qrCode', 'resource', 'slider',   'spinner', 'video'];
}

export const getElementMoves = () => {
  return ['fromLeft', 'fromRight', 'fromTop', 'fromBottom'];
}

export const getCodeTypes = () => {
  return ['CODE_128', 'QR_CODE', 'EAN_13', 'DATA_MATRIX'];
}

export const getDescriptionText = (key?: string) => {
  if (!key) {
    return "";
  }
  switch (key) {
    case 'image': {
      return 'Image URL'
    }
    case 'iframe': {
      return 'Webview URL'
    }
    case 'color': {
      return '#XXXXXX'
    }
    default: {
      return ""
    }
  }
}
